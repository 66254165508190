<template>
  <v-row justify="center">
    <v-col
      md="8"
      sm="10"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <h1 class="text-center my-5">
          La Formation Pro
        </h1>
        <h2 class="text-center my-5">
          Enquète de satisfaction à chaud
        </h2>
        <v-card>
          <v-card-title>
            <h4>
              Informations personnelles
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="review.nom"
                  label="Nom de famille"
                  :rules="[v => !!v || 'Le nom est requis']"
                  outlined
                  dense
                  placeholder="Nom de famille"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="review.prenom"
                  label="Prénom"
                  :rules="[v => !!v || 'Le prénom est requis']"
                  outlined
                  dense
                  placeholder="Prénom"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Accueil
            </h4>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              Êtes-vous satisfait des informations préalables qui vous ont été donnée et de l'accueil en formation ?
            </p>
            <v-radio-group
              v-model="review.accueil"
              row
            >
              <v-radio
                v-for="n in evaluation"
                :key="n.value"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Contenu de la formation
            </h4>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              Etes-vous satisfait du programme, des supports pédagogiques, de l'organisation des modules, de l'alternance théorie/pratique de votre formation ?
            </p>
            <v-radio-group
              v-model="review.contenu"
              row
            >
              <v-radio
                v-for="n in evaluation"
                :key="n.value"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Equipe de formateurs
            </h4>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              Etes-vous satisfait de la qualité de l'enseignement, de la disponibilité, et des explications données par vos formateurs durant votre formation ?
            </p>
            <v-radio-group
              v-model="review.equipe"
              row
            >
              <v-radio
                v-for="n in evaluation"
                :key="n.value"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Moyens mis à disposition
            </h4>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              Etes-vous satisfait de l'interface informatique mise à disposition ?
            </p>
            <v-radio-group
              v-model="review.moyens"
              row
            >
              <v-radio
                v-for="n in evaluation"
                :key="n.value"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Accompagnement
            </h4>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              Avons-nous pris en compte vos demandes et vos besoins ?
            </p>
            <v-radio-group
              v-model="review.accompagnement"
              row
            >
              <v-radio
                v-for="n in evalMoyen"
                :key="n.value"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Recommanderiez-vous cette formation ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-radio-group
              v-model="review.recommandation"
              row
            >
              <v-radio
                label="Oui"
                :value="true"
              ></v-radio>
              <v-radio
                label="Non"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Quels sont les points forts de cette formation ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="review.points_forts"
              outlined
              label="Points forts"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Quels sont les points faibles de cette formation ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="review.points_faibles"
              outlined
              label="Points faibles"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Note générale de la formation ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-rating
              v-model="review.note"
              :empty-icon="icons.mdiStarOutline"
              :full-icon="icons.mdiStar"
              :half-icon="icons.mdiStarHalfFull"
              hover
              length="5"
              size="64"
            ></v-rating>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              Autres remarques
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="review.remarque"
              outlined
              label="Autres remarques"
            ></v-textarea>
          </v-card-text>
          <v-card-text class="text-center">
            <v-dialog
              v-model="dialog"
              transition="dialog-top-transition"
              max-width="600"
            >
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  @click="validateReview"
                >
                  Valider
                </v-btn>
              </template>
              <template>
                <v-card>
                  <v-toolbar
                    color="primary"
                    dark
                  >
                    Enquête de satisfaction terminée
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      Merci, l'enquète est terminée.
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      color="primary"
                      @click.prevent="endReview"
                    >
                      Fermer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>

import axios from 'axios'
import { mdiStar, mdiStarOutline, mdiStarHalfFull } from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      valid: true,
      icons: {
        mdiStar,
        mdiStarOutline,
        mdiStarHalfFull,
      },
      review: {
        type: 'chaud',
        nom: '',
        prenom: '',
        accueil: 4,
        contenu: 4,
        equipe: 4,
        moyens: 4,
        accompagnement: 3,
        recommandation: true,
        points_forts: '',
        points_faibles: '',
        note: 5,
        remarque: '',
      },
      evaluation: [
        {
          text: 'Pas du tout',
          value: 1,
        },
        {
          text: 'Insuffisamment',
          value: 2,
        },
        {
          text: 'En partie',
          value: 3,
        },
        {
          text: 'Totalement',
          value: 4,
        },
      ],
      evalMoyen: [
        {
          text: 'Non',
          value: 1,
        },
        {
          text: 'Un peu',
          value: 2,
        },
        {
          text: 'Beaucoup',
          value: 3,
        },
      ],
    }
  },
  methods: {
    validateReview() {
      if (this.$refs.form.validate()) {
        axios.post(`${process.env.VUE_APP_API_URL}/reviews/`, this.review)
          .catch(err => console.log(err))
          .then(() => {
            this.dialog = true
          })
      } else {
        window.scrollTo(0, 0)
      }
    },
    endReview() {
      this.dialog = false

      document.location.href = 'https://laformationpro.fr'
    },
  },
}
</script>

<style>

</style>
